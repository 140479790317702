import { SocialApiTypes } from '@wix/social-groups-api/dist/src/types';
import { extractMedia } from 'ricos-content/libs/extract-media';
import {
  ExtractedMediaSEO,
  GroupSEOItemType,
  IImageSEO,
  IMediaSEO,
  IVideoSEO,
} from './GroupSEOItemType';
import { IFeedItem } from '../types/IFeedItem';
import { ContentConverter } from '../../../common/ContentEditor/content/ContentConverter';

export function getGroupSEOItemType(): GroupSEOItemType {
  return GroupSEOItemType.GROUPS_PAGE;
}

export function feedItemsWithPlainTextContent(srcFeedItems: IFeedItem[]) {
  return srcFeedItems.map(feedItemWithPlainTextContent);
}

function getPlainPostBody(feedItem: IFeedItem) {
  if (!feedItem.entity || !feedItem.entity.body) {
    return '';
  }
  return feedItem.entity.body.contentType === SocialApiTypes.ContentType.DRAFTJS
    ? draftJsStringToPlainText(feedItem.entity.body.content)
    : feedItem.entity.body.content;
}

export function feedItemWithPlainTextContent(feedItem: IFeedItem) {
  return {
    ...feedItem,
    entity: {
      body: getPlainPostBody(feedItem),
    },
  };
}

export function mediaFromFeedItems(feedItems: IFeedItem[]): IMediaSEO {
  return feedItems.reduce(
    function (acc, feedItem) {
      if (!hasDraftJs(feedItem)) {
        return acc;
      }

      // @ts-expect-error
      const extractedMedia: ExtractedMediaForSEO[] = extractMedia(
        ContentConverter.parseContentString(feedItem.entity!.body.content),
      );
      if (!(extractedMedia && extractedMedia.length)) {
        return acc;
      }

      return extractedMedia.reduce(splitVideosAndImages, acc);
    },
    {
      videosData: [],
      imagesData: [],
    },
  );
}

function splitVideosAndImages(acc: IMediaSEO, mediaItem: ExtractedMediaSEO) {
  if (mediaItem.hasOwnProperty('videoThumbnailUrl')) {
    acc.videosData.push(mediaItem as IVideoSEO);
  } else {
    acc.imagesData.push(mediaItem as IImageSEO);
  }
  return acc;
}

function hasDraftJs(feedItem: IFeedItem): boolean {
  return !!(
    feedItem.entity &&
    feedItem.entity.body &&
    feedItem.entity.body.contentType === SocialApiTypes.ContentType.DRAFTJS
  );
}

function draftJsStringToPlainText(draftJs: string): string {
  try {
    const content = JSON.parse(draftJs);
    const texts = content.blocks.map((block: any) => block.text);
    return texts.join('\n').trim();
  } catch (e) {
    // console.error(e);
    return draftJs;
  }
}
