import { ActivityApi } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { canSeeGroup } from '@wix/social-groups-api/dist/src/model/Member/permissions';
import { Controller } from '../Controller';
import { ActivityControllerProps } from './ActivityControllerProps';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { BaseControllerContext } from 'common/controllers';

export class ActivityController extends Controller<ActivityControllerProps> {
  private api: ActivityApi;

  constructor(controllerContext: ControllerParams, groupId: string) {
    super(controllerContext, groupId);
    this.api = new ActivityApi(
      this.getSiteToken()!,
      this.getApiBaseUrl(),
      new BaseControllerContext(controllerContext),
    );
    this.onUserLogin(() => {
      this.api = new ActivityApi(
        this.getSiteToken()!,
        this.getApiBaseUrl(),
        new BaseControllerContext(controllerContext),
      );
    });
  }

  setProps(props: Partial<ActivityControllerProps>) {
    this.controllerConfig.setProps(props);
  }

  async pageReady(): Promise<void> {
    this.setProps({
      activities: undefined,
      activityActions: {
        getActivity: this.getActivity,
      },
    });
  }

  private readonly getActivity = async (group: ApiTypes.v1.GroupResponse) => {
    if (!canSeeGroup(group)) {
      return;
    }
    let newPosts = 0;
    try {
      const { totalPosts } = await this.api.getActivities(group.groupId!);
      newPosts = totalPosts || 0;
    } catch (e) {
      console.error('Get group activity: FAIL');
      this.errorLogger.log(e);
    }
    this.setProps({ activities: { newPosts } });
  };
}
