import { Controller } from '../Controller';
import { ErrorHandlerControllerProps } from './ErrorHandlerControllerProps';
import { ErrorOrigin, IErrorEvent } from './IErrorEvent';
import { PubSubObserver } from '../pubSub/PubSubObserver';
import { PubSubEventTypes } from '../pubSub/PubSubEventTypes';
import { ControllerParams } from '@wix/yoshi-flow-editor';

export class ErrorHandlerController
  extends Controller<ErrorHandlerControllerProps>
  implements PubSubObserver {
  private readonly initError: IErrorEvent;

  constructor(controllerContext: ControllerParams, groupId: string) {
    super(controllerContext, groupId);
    this.initError = groupId
      ? (null as any)
      : { origin: ErrorOrigin.Group, code: 404 };
    this.setSubscriptions();
  }

  getInitialProps(): Promise<Partial<ErrorHandlerControllerProps>> {
    return Promise.resolve({
      errorEvents: { [ErrorOrigin.Group]: this.initError },
      errorHandlers: {
        actionByError: this.actionByError,
        ignoreError: this.ignoreError,
      },
    });
  }

  async pageReady(): Promise<any> {
    return Promise.resolve();
  }

  removeSubscriptions() {}

  setSubscriptions() {
    this.controllerConfig.platformAPIs.pubSub.subscribe(
      PubSubEventTypes.CONTROLLER_ERROR,
      this.handleErrorEvent,
      false,
    );
  }

  private readonly handleErrorEvent = ({ data }: any) => {
    try {
      const errEvent: IErrorEvent = JSON.parse(data);
      this.controllerConfig.setProps({
        errorEvents: { [errEvent.origin]: errEvent },
      });
    } catch (e) {}
  };

  private readonly actionByError = (error: IErrorEvent) => {
    // TODO: other error handlers
    return this.goToGroupList();
  };

  private ignoreError = (errEvent: IErrorEvent) => {
    this.controllerConfig.setProps({
      errorEvents: { [errEvent.origin]: null },
    });
  };
}
