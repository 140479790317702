import { WixApi } from '@wix/social-groups-api/dist/src/types';
import { ApiErrorCode } from '../../../components/Group/controllers/errorHandler/IErrorEvent';

export interface IApplicationError {
  message: string;
  details: WixApi.Details;
}
export class ApplicationError extends Error implements IApplicationError {
  readonly details: WixApi.Details;

  constructor(err: IApplicationError) {
    super(err.message);
    this.details = err.details || {};
  }

  getCode() {
    return this.details?.applicationError?.code as ApiErrorCode;
  }

  getErrorData<T>() {
    return this.details.applicationError?.data as T;
  }
}
