import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { ApiHostName } from '@wix/social-groups-api/dist/src/types';

import Url from 'url-parse';

export const GROUPS_BASE_API_URL = '/social-groups/api/';
export const RICH_CONTENT_API_URL = `/rich-content`;
export const DUPLEXER_API_URL = `/wix-duplexer-sockets-server`;

/**
 * Resolve social groups api base path depending on the `wixCodeApi.window.viewMode`
 * i.e. in Editor it would be `https://groups.wixapps.net/social-groups/api/`
 *
 * (see https://www.wix.com/corvid/reference/wix-window.html for more on `wixCodeApi.window`)
 */
export default function getBaseUrl(wixCodeApi: IWixAPI): string {
  const hostname = (self.location && self.location.hostname) || '';
  const { baseUrl } = wixCodeApi.location;
  const locationUrl = baseUrl.includes(hostname)
    ? baseUrl
    : (self.location && self.location.origin) || '';
  return getApiBaseUrl(locationUrl);
}

export function getApiBaseUrl(locationUrl: string) {
  const { origin, hostname } = new Url(locationUrl);
  let apiUrl: string;
  switch (hostname) {
    // all requests after page ready in editor "edit" mode
    case ApiHostName.GROUPS:
    // dashboard
    // eslint-disable-next-line no-fallthrough
    case ApiHostName.WIX:
      apiUrl = GROUPS_BASE_API_URL;
      break;
    // all requests before page ready in editor "preview" mode
    case ApiHostName.EDITOR:
      apiUrl = '/_api/social-groups-web/';
      break;
    case 'localhost':
    // live site
    // eslint-disable-next-line no-fallthrough
    default:
      apiUrl = `/_api/social-groups/`;
  }
  return `${origin}${apiUrl}`;
}

function getApiURL(locationUrl: string, apiUrl: string) {
  const url = new Url(locationUrl);
  return `${url.origin}${apiUrl}`;
}

export function getSocialApiBaseUrl(locationUrl: string) {
  const apiUrl = `/social-content/api/v1/`;
  // TODO: social api doesn't work in editor preview and some other cases? TEST
  return getApiURL(locationUrl, apiUrl);
}

export function getDuplexerApiUrl(locationUrl: string) {
  const url = new Url(locationUrl);

  let host;

  if (url.host.includes(ApiHostName.DEV_WIX_CODE)) {
    host = ApiHostName.WIX;
  } else {
    host = url.host;
  }

  return `${host}${DUPLEXER_API_URL}`;
}

export function getRCApiBaseUrl(locationUrl: string) {
  // TODO: rich-content api doesn't work in editor preview and some other cases? TEST
  return getApiURL(locationUrl, RICH_CONTENT_API_URL);
}

export function getClubsApiUrl(locationUrl: string) {
  const { hostname } = new Url(locationUrl);
  if (hostname === ApiHostName.EDITOR) {
    return `//groups.wixapps.net/api/v1/`;
  }

  const apiUrl = `/clubs/api/v1`;
  return getApiURL(locationUrl, apiUrl);
}
