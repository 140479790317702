import {
  feedItemsWithPlainTextContent,
  getGroupSEOItemType,
  mediaFromFeedItems,
} from './utils';
import { isGroupMember } from '@wix/social-groups-api';
import { GroupDTO } from '@wix/social-groups-api/dist/src/model/Group/GroupDTO';

import { GroupSEO } from './GroupSEO';
import { GroupSEOTabType } from './GroupSEOItemType';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { ContentConverter } from '../../../common/ContentEditor/content/ContentConverter';
import { ConsoleLogger } from '../../../common/loggers/ConsoleLogger';
import { Tab } from '../../../common/controllers/group-url';

// TODO: content(group description, feed items content) should be in plain text
export class SeoGroupBuilder {
  private readonly itemData: GroupSEO;

  constructor() {
    this.itemData = {
      directUrl: '',
      group: undefined as any,
      tabType: undefined as any,
      tabs: undefined as any,
    };
  }

  withGroup(group: ApiTypes.v1.GroupResponse) {
    if (this.itemData && this.itemData.group) {
      return this;
    }
    const groupDTO = new GroupDTO(group);
    const description = ContentConverter.parseText(groupDTO.getDescription());
    const logo = { height: 300, width: 300 };
    const logoUrl = groupDTO.getScaledLogo(logo.width, logo.height);

    const g = groupDTO.setDetails({ description, logoUrl, logo });
    this.itemData.group = g;
    return this;
  }

  withTabs(tabs: any) {
    this.itemData.tabs = tabs;
    return this;
  }

  private getItemData(): GroupSEO {
    const { feedItems, groupMembers, ...itemData } = this.itemData;
    try {
      switch (this.itemData.tabType) {
        case GroupSEOTabType.FEED:
          (itemData as GroupSEO).feedItems = feedItems;
          break;
        case GroupSEOTabType.MEMBERS:
          (itemData as GroupSEO).groupMembers = groupMembers;
          break;
        default:
      }
    } catch (e) {
      ConsoleLogger.log(e);
    }
    return itemData;
  }

  withFeed(feedItems: any) {
    this.itemData.feedItems = feedItemsWithPlainTextContent(feedItems);
    const media = mediaFromFeedItems(feedItems);
    this.itemData.videosData = media.videosData;
    this.itemData.imagesData = media.imagesData;
    return this;
  }

  withMembers(members: ApiTypes.v1.SiteMemberProfileResponse[]) {
    this.itemData.groupMembers = members.filter(isGroupMember);
    return this;
  }

  forLocation(url: string, activeTab: Tab) {
    this.itemData.tabType = this.getTabType(activeTab);
    this.itemData.directUrl = url;
    return this;
  }

  public build(): {
    itemType: string;
    itemData: GroupSEO;
  } {
    return {
      itemType: getGroupSEOItemType(),
      itemData: this.getItemData(),
    };
  }

  private getTabType(activeTab: Tab) {
    switch (activeTab) {
      case Tab.ABOUT:
        return GroupSEOTabType.ABOUT;
      case Tab.MEMBERS:
        return GroupSEOTabType.MEMBERS;
      case Tab.MEDIA:
        return GroupSEOTabType.MEDIA;
      default:
        return GroupSEOTabType.FEED;
    }
  }
}
